import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'translate',
})
export class translate implements PipeTransform {
  transform(value: any, ...args: any[]) {
    if (args[0] === 'fr') {
      return value;
    } else if (args[0] === 'en') {
      return args[1];
    } else {
      return value;
    }
  }
}
