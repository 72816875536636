import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { IsBrowserService } from './is-browser-service.service';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  private readonly localStorage: any;

  constructor(
    private isBrowserService: IsBrowserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(platformId)) {
      this.localStorage = window.localStorage;
    } else {
      this.localStorage = null;
    }
  }

  getItem(key: string): any {
    if (this.localStorage) {
      return this.localStorage.getItem(key);
    }
    return null;
  }

  setItem(key: string, value: any): void {
    if (this.localStorage) {
      this.localStorage.setItem(key, value);
    }
  }

  removeItem(key: string): void {
    if (this.localStorage) {
      this.localStorage.removeItem(key);
    }
  }

  clear(): void {
    if (this.localStorage) {
      this.localStorage.clear();
    }
  }
}
